.swipper-empreendimentos {
  padding: 16px 10px 80px 10px;
  max-width: 46rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media screen and(max-width:500px) {
    max-width: 25rem;
    padding: 10px 5px 70px 10px;
    .anchor-swiper {
      display: flex;
      justify-content: center;
    }
    .back-swiper-image {
      display: none !important;
    }
    .footer-card-info {
      padding: 10px;
      opacity: 0.9;
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: -100px;
      margin: 0px 15px;
      height: 150px;

      background-color: white;
      width: 170px;
      box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.3);
      h3 {
        color: var(--gold-color);
        font-size: 1rem;
      }

      .footer-info {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: start;
        align-items: flex-start;
        div {
          display: flex;
          align-items: center;
          .icon-empreendimento {
            margin-right: 10px;
            height: 24px;
            width: 24px;
            width: fit-content;
          }
          p {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
  h3 {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
  }
  p {
    text-align: center;
    font-size: 1rem;
    color: var(--black-color);
  }

  font-size: 18px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

  .footer {
    width: 100%;
  }

  .img-swiper-empreedimento {
    display: flex;

    object-fit: cover;
    border-radius: 0.2rem;
    position: relative;
    height: 350px;
    width: 200px;
    transition: 0.4s ease all;
    transform: translateY(0px);
    cursor: pointer;
    &:hover {
      transform: translateY(-20px);
      transition: 0.4s ease all;
    }
  }
  .back-swiper-image {
    opacity: 0.4;
    display: flex;
    top: -10px;
    left: -10px;
    position: absolute;
    height: 350px;
    width: 200px;
    border: 1px solid var(--gold-color);

    z-index: -1;
  }
  .footer-card-info {
    padding: 10px;
    opacity: 0.9;
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: -100px;
    margin: 0px 15px;
    height: 150px;
    background-color: white;
    width: 170px;
    box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.3);
    h3 {
      color: var(--gold-color);
      font-size: 1rem;
    }
    .footer-info {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: start;
      align-items: flex-start;
      div {
        display: flex;
        align-items: center;
        .icon-empreendimento {
          margin-right: 10px;
          height: 24px;
          width: 24px;
          width: fit-content;
        }
        p {
          font-size: 0.8rem;
        }
      }
    }
  }
  .swiper-button-next {
    margin-right: 20px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    &:after {
      font-family: swiper-icons;
      font-size: 16px;
      font-weight: 600;
      color: var(--gold-color);
      text-transform: none !important;
      letter-spacing: 0;
      text-transform: none;
      font-variant: initial;
      line-height: 1;
    }
  }
}

