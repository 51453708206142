.swipper-comodidade {
  max-width: 48rem;
  height: 100%;
  padding: 20px 0px;

  h3 {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
  }
  p {
    text-align: center;
    font-size: 1rem;
  }

  font-size: 18px;

  .footer {
    width: 100%;
  }

  img {
    height: 50px;
    width: 50px;
    display: block;

    object-fit: fill;
    border-radius: 0.2rem;
    margin-bottom: 1rem;
  }

  .swiper-button-next,
  .swiper-button-prev {
    &:after {
      font-family: swiper-icons;
      font-size: 16px;
      font-weight: 600;
      color: var(--gold-color);
      text-transform: none !important;
      letter-spacing: 0;
      text-transform: none;
      font-variant: initial;
      line-height: 1;
    }
  }
}

