.SwipperGaleria {
  max-width: 48rem;
  height: 100%;

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    position: relative;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    button {
      border: none;
    }
    .footer {
      width: 100%;
      h3 {
        text-align: start;
        font-size: 0.9rem;
        font-weight: 600;
        color: var(--gold-color);
        text-transform: uppercase;
        text-shadow: 0px 1px 3px rgba(46, 46, 46, 0.5);
      }
      p {
        text-align: start;
        font-size: 1rem;
        color: var(--shape-color);
      }
    }
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;

    margin-bottom: 1rem;
  }

  @media screen and (max-width: 500px) {
    .swiper-slide {
      .footer {
        position: relative;
        bottom: initial;
        left: initial;
      }
    }
  }

  .swiper-button-next {
    right: 50px;
    padding: 30px;
    border: white solid 2px;
    opacity: 0.5;
    border-radius: 50%;
    transition: 0.3s all ease;
    &:after {
      font-family: swiper-icons;
      font-size: 13px;
      font-weight: 600;
      color: white;
      text-transform: none !important;
      letter-spacing: 0;
      text-transform: none;
      font-variant: initial;
      line-height: 1;
      opacity: 1;
    }
    &:hover {
      color: var(--gold-color);
      transition: 0.3s all ease;
      opacity: 1;
      border: var(--gold-color) solid 2px;
    }
  }
  .swipper-pagination {
    display: flex;
    color: var(--gold-color);

    bottom: 10px;
    z-index: 999999;
    font-size: 0.9rem;
  }
  .full-screen-button {
    align-items: center;

    display: flex;
    position: absolute;
    padding: 10px 20px;
    font-size: 0.7rem;
    color: white;
    font-weight: 700;
    background-color: rgba(255, 255, 255, 0.1);
    border: none;
    border-radius: 20px;
    backdrop-filter: blur(5px);
    top: 20px;
    left: 40%;
    z-index: 999999999;
    transition: 0.7s ease;
    &:hover {
      background-color: var(--gold-color);
      transition: 0.7s ease;
    }
    .icon-fs {
      width: 17px;
      height: 17px;
      margin-left: 10px;

      background-repeat: no-repeat;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    top: initial;
    bottom: 60px;
  }

  .swiper-button-prev {
    left: initial;
    right: 10rem;
    padding: 30px;
    border: white solid 2px;
    border-radius: 50%;
    transition: 0.3s all ease;
    opacity: 0.5;
    &:after {
      font-family: swiper-icons;
      font-size: 13px;
      font-weight: 600;
      color: white;
      text-transform: none !important;
      letter-spacing: 0;
      text-transform: none;
      font-variant: initial;
      line-height: 1;
      &:hover {
        color: var(--gold-color);
      }
    }
    &:hover {
      color: var(--gold-color);
      transition: 0.3s all ease;
      opacity: 1;
      border: var(--gold-color) solid 2px;
    }
  }
}

